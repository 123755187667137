/**
 * @author Mr_FabiozZz[mr,fabiozzz@gmail.com]
 */
import {
  HandbookContext,
  TTabType
} from 'pages/Calculations/components/CalculationDirectory/handbook.services';
import { FC, memo, useContext, useMemo } from 'react';
import { Item, LeftMenu } from '../Parameters.styles';
import { TTab } from '../Parameters.types';
import { OrNull } from 'api/references/estimates/estimates.types';
import { ActList } from 'types';

const tabs: {
  name: 'indirect_costs' | 'value_changes' | 'acts';
  value: string;
}[] = [
  {
    name: 'acts',
    value: 'Акты по периодам'
  },
  {
    name: 'indirect_costs',
    value: 'Косвенные затраты'
  },
  {
    name: 'value_changes',
    value: 'Коэффициент изменения стоимости'
  }
];

export const Menu: FC<{
  tab: TTab;
  setTab: (str: TTab) => void;
  selectedTab: TTabType | null;
  isActs: boolean;
  disabled: boolean;
  isCoefficient: boolean;
  currentAct: OrNull<ActList>;
}> = memo(({ tab, setTab, isActs, currentAct, isCoefficient }) => {
  const list = useMemo(() => {
    return !isActs ? tabs.filter((tab) => tab.name !== 'acts') : tabs;
  }, [isActs]);
  const { actList: actListOriginal } = useContext(HandbookContext);

  return (
    <LeftMenu>
      {list.map((item, index) => (
        <Item
          key={index}
          active={item.name === tab}
          style={{
            opacity:
              item.name === 'acts'
                ? actListOriginal?.actList.length
                  ? 1
                  : 0.5
                : currentAct
                  ? isCoefficient
                    ? 1
                    : 0.5
                  : 0.5
          }}
          onClick={() =>
            item.name === 'acts'
              ? actListOriginal?.actList.length
                ? item.name !== tab && setTab(item.name)
                : undefined
              : currentAct
                ? isCoefficient
                  ? item.name !== tab && setTab(item.name)
                  : undefined
                : undefined
          }>
          {item.value}
        </Item>
      ))}
    </LeftMenu>
  );
});

Menu.displayName = 'Menu';
