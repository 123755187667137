/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from '../api';
import {
  AutomaticTreeProtectionRequest,
  AutomaticTreeProtectionResponse,
  BasePricesItem,
  BasicLevelType,
  BindGroupRequest,
  CalculationBaseResponse,
  CalculationUploadFilesRequest,
  ChangeLevelNameRequest,
  ChangeLevelRequest,
  ClearEmptyLevelRequest as ClearEmptyLevelsRequest,
  CoefficientAddRequest,
  ComplexBaseResponse,
  ComplexLevelAddRequest,
  ComplicatedResource,
  CreateCalculationRequest,
  CreateLevelRequest,
  DeleteGroupRequest,
  DeleteRowByIdCalculationRequest,
  DownloadCalculationRequest,
  DownloadExcelRequest,
  EditCalculationByIdRequest,
  GetAvailableGroupsRequest,
  GetAvailableGroupsResponse,
  GetCalculationByIdPreviewRequest,
  GetCalculationByIdRequest,
  GetCalculationListResponse,
  GetCalculationsListRequest,
  GetComplexListResponse,
  GetComplicatedListResponse,
  GetEditCalculationByIdRequest,
  GetEditComplexRowByIdRequest,
  GetEditRowByIdRequest,
  GetEditStatusCalculationByIdRequest,
  GetEditTitleRowByIdRequest,
  GetFilteredVorPositionsRequest,
  GetFilteredVorPositionsResponse,
  GetGroupPositionResponse,
  GetKeyWorkRequest,
  GetKeyWorkResponse,
  GetPositionListRequest,
  GetResourceListRequest,
  GetResourceListResponse,
  GetRowsListRequest,
  GetRowsListResponse,
  GetVorPositionsRequest,
  GetVorPositionsResponse,
  IComplicated,
  LevelAddRequest,
  LevelBaseResponse,
  RenameLevelRequest,
  RowAddRequest,
  RowBaseResponse,
  RowCopyRequest,
  Total,
  TransferElementToTheGroupRequest,
  TransformedResourceResponse,
  UploadExcelRequest
} from './types';

import { groupBy } from 'lodash';

import {
  ActList,
  ActResponse,
  CalcData,
  CalcID,
  ComplicatedPositionTreeFile,
  CreateActRequest,
  EditQuantityRequest,
  ExecutionCalculationData,
  GetExecutionCalculationData,
  HandbookExecutionListResponse,
  PartExecution,
  SyncActRequest,
  SyncActResponse
} from 'types';

import {
  changedComplexEditRow,
  changedComplexSumRow,
  changedCopyBasicRow,
  changedCopyComplexRow,
  changedCreateBasicLevel,
  changedCreateBasicRow,
  changedCreateComplexLevel,
  changedCreateComplexRow,
  changedDeleteRow,
  changedEditRow,
  changedSumBasicRow
} from './helpers';

export const calculationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCalculationList: build.query<
      GetCalculationListResponse,
      GetCalculationsListRequest
    >({
      query: ({ projectID, filters }) => {
        return {
          url: `/project/${projectID}/calc/list`,
          method: 'GET',
          params: { filter: filters ?? undefined }
        };
      },
      providesTags: ['Calculations'],
      keepUnusedDataFor: 0
    }),
    createCalculation: build.mutation<CalcData, CreateCalculationRequest>({
      query: ({ params, body }) => ({
        url: `/project/${params.projectID}/calc/create`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result ? ['Calculations'] : [])
    }),
    calculationUploadFiles: build.mutation<void, CalculationUploadFilesRequest>(
      {
        query: ({ params, body }) => ({
          url: `/project/${params.projectID}/calc/${params.calcID}/upload`,
          method: 'POST',
          body
        })
      }
    ),
    getCalculationById: build.query<CalcData, GetCalculationByIdRequest>({
      query: ({ projectID, calcID, isPreview }) => {
        let url;
        if (!isPreview) {
          url = `/project/${projectID}/calc/${calcID}/get`;
        } else {
          url = `/project/${projectID}/lsr/files/${calcID}/get`;
        }
        return {
          url: url,
          method: 'GET'
        };
      },
      transformResponse(response: CalcData) {
        return response;
      },
      providesTags: ['CalculationByID']
    }),
    editCalculationById: build.mutation<CalcData, EditCalculationByIdRequest>({
      query: ({ calcID, title, description, projectID }) => {
        return {
          url: `/project/${projectID}/calc/${calcID}/update`,
          method: 'PATCH',
          body: {
            description,
            title
          }
        };
      },
      invalidatesTags: ['CalculationByID', 'Calculations'],
      transformResponse(response: CalcData) {
        return response;
      }
    }),
    getCalculationByIdPreview: build.query<unknown, unknown>({
      query: ({ projectID, lsrID }) => {
        return {
          url: `/project/${projectID}/lsr/files/${lsrID}/preview`,
          method: 'GET'
        };
      }
    }),
    getLsrPreview: build.query<CalcData, GetCalculationByIdPreviewRequest>({
      query: ({ projectID, calcID }) => {
        return {
          url: `/project/${projectID}/lsr/files/${calcID}/preview`,
          method: 'GET'
        };
      }
    }),

    editCalculationStatus: build.mutation<
      CalculationBaseResponse,
      GetEditStatusCalculationByIdRequest
    >({
      query: ({ id, ...body }) => ({
        url: `/calculation/${id}/edit`,
        method: 'POST',
        body
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log('error editCalculationStatus', err);
        }
      }
    }),
    deleteCalculation: build.mutation<unknown, GetCalculationByIdRequest>({
      query: ({ projectID, calcID }) => ({
        url: `/project/${projectID}/calc/${calcID}/delete`,
        method: 'DELETE'
      }),
      invalidatesTags: ['DeleteCalculations', 'Calculations']
    }),
    copyCalculation: build.mutation<unknown, GetCalculationByIdRequest>({
      query: ({ calcID }) => {
        return {
          url: `/calculation/${calcID}/copy`,
          method: 'GET'
        };
      },
      invalidatesTags: ['CopyCalculation']
    }),
    downloadCalculation: build.mutation<string, DownloadCalculationRequest>({
      query: ({ projectID, calcID }) => ({
        url: `/project/${projectID}/calc/${calcID}/download`,
        method: 'GET',
        headers: {
          'content-type': 'text/plain'
        },
        responseHandler: 'text'
      })
    }),
    editCalculation: build.mutation<
      CalculationBaseResponse,
      GetEditCalculationByIdRequest
    >({
      query: ({ id, ...body }) => {
        return {
          url: `/calculation/${id}/edit`,
          method: 'POST',
          body
        };
      }
    }),
    getBasicRowsList: build.query<GetRowsListResponse, GetRowsListRequest>({
      query: ({ id }) => ({
        url: `/calculation/${id}/basic/get`,
        method: 'GET'
      }),
      providesTags: ['RowsList']
    }),
    createBasicLevel: build.mutation<LevelBaseResponse, LevelAddRequest>({
      query: ({ id: calculationId, ...body }) => {
        return {
          url: `/calculation/${calculationId}/basic/row/add`,
          method: 'POST',
          body
        };
      },
      async onQueryStarted(
        { id: calculationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: createLevel } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getBasicRowsList',
              { id: calculationId },
              (draft) => {
                changedCreateBasicLevel(draft.data, createLevel.current);
              }
            )
          );
        } catch (err) {
          console.log('error createBasicLevel', err);
        }
      }
    }),
    copyBasicLevel: build.mutation<RowBaseResponse, RowCopyRequest>({
      query: ({ id: calculationId, rowId }) => {
        return {
          url: `/calculation/${calculationId}/basic/row/${rowId}/copy`,
          method: 'POST'
        };
      },
      async onQueryStarted(
        { id: calculationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: copyRow } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getBasicRowsList',
              { id: calculationId },
              (draft) => {
                changedCopyBasicRow(draft.data, copyRow.current);
                changedSumBasicRow(draft.data, copyRow.changed);
              }
            )
          );
        } catch (err) {
          console.log('error copyBasicLevel', err);
        }
      }
    }),
    createRow: build.mutation<RowBaseResponse, RowAddRequest>({
      query: ({ id: calculationId, ...body }) => {
        return {
          url: `/calculation/${calculationId}/basic/row/add`,
          method: 'POST',
          body
        };
      },
      async onQueryStarted(
        { id: calculationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: createRow } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getBasicRowsList',
              { id: calculationId },
              (draft) => {
                changedCreateBasicRow(draft.data, createRow.current);
                changedSumBasicRow(draft.data, createRow.changed);
              }
            )
          ); // dispatch
        } catch (err) {
          console.log('error createRow', err);
        }
      }
      //  invalidatesTags: ['CreateRow'],
    }),
    deleteRow: build.mutation<RowBaseResponse, DeleteRowByIdCalculationRequest>(
      {
        query: ({ calculationId, rowId }) => {
          return {
            url: `calculation/${calculationId}/basic/row/${rowId}/delete`,
            method: 'DELETE'
          };
        },
        async onQueryStarted({ calculationId }, { dispatch, queryFulfilled }) {
          try {
            const { data: deleteRow } = await queryFulfilled;
            dispatch(
              calculationsApi.util.updateQueryData(
                'getBasicRowsList',
                { id: calculationId },
                (draft) => {
                  changedDeleteRow(draft.data, deleteRow.current);
                  changedSumBasicRow(draft.data, deleteRow.changed);
                }
              )
            );
          } catch (err) {
            console.log('error deleteRow', err);
          }
        }
        // invalidatesTags: ['DeleteRow'],
      }
    ),
    editTitleLevel: build.mutation<RowBaseResponse, GetEditTitleRowByIdRequest>(
      {
        query: ({ calculationId, rowId, ...body }) => {
          return {
            url: `calculation/${calculationId}/basic/row/${rowId}/edit`,
            method: 'POST',
            body
          };
        },
        async onQueryStarted({ calculationId }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedTitle } = await queryFulfilled;
            dispatch(
              calculationsApi.util.updateQueryData(
                'getBasicRowsList',
                { id: calculationId },
                (draft) => {
                  function changedTitle(arr: BasicLevelType[]) {
                    if (!arr.length) {
                      return;
                    }
                    arr.forEach((elem) => {
                      if (elem.id === updatedTitle.current.id) {
                        elem.title = updatedTitle.current.title;
                      }
                      if (elem.type === 'level')
                        return changedTitle(elem.children);
                    });
                  }

                  changedTitle(draft.data);
                }
              )
            );
          } catch (err) {
            console.log('error editTitleLevel', err);
          }
        }
        //invalidatesTags: ['DeleteRow'],
      }
    ),
    editRow: build.mutation<RowBaseResponse, GetEditRowByIdRequest>({
      query: ({ calculationId, rowId, ...body }) => {
        return {
          url: `calculation/${calculationId}/basic/row/${rowId}/edit`,
          method: 'POST',
          body
        };
      },
      async onQueryStarted({ calculationId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedRowsList } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getBasicRowsList',
              { id: calculationId },
              (draft) => {
                changedEditRow(draft.data, updatedRowsList.current);
                changedSumBasicRow(draft.data, updatedRowsList.changed);
              }
            )
          );
        } catch (err) {
          console.log('error editRow', err);
        }
      }
      //  invalidatesTags: ['CreateRow'],
    }),
    getComplexRowsList: build.query<GetComplexListResponse, GetRowsListRequest>(
      {
        query: ({ id }) => ({
          url: `/calculation/${id}/complex/get`,
          method: 'GET'
        }),
        providesTags: ['ComplexList']
      }
    ),
    createComplexLevel: build.mutation<
      ComplexBaseResponse,
      ComplexLevelAddRequest
    >({
      query: ({ id: calculationId, ...body }) => {
        return {
          url: `/calculation/${calculationId}/complex/row/add`,
          method: 'POST',
          body
        };
      },
      async onQueryStarted(
        { id: calculationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: createBasePrices } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getComplexRowsList',
              { id: calculationId },
              (draft) => {
                changedCreateComplexLevel(draft.rows, createBasePrices.current);
              }
            )
          );
        } catch (err) {
          console.log('error createComplexLevel', err);
        }
      }
      // invalidatesTags: ['ComplexList'],
    }),
    createComplexRow: build.mutation<
      ComplexBaseResponse,
      ComplexLevelAddRequest
    >({
      query: ({ id: calculationId, ...body }) => {
        return {
          url: `/calculation/${calculationId}/complex/row/add`,
          method: 'POST',
          body
        };
      },
      async onQueryStarted(
        { id: calculationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: updatedRowsList } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getComplexRowsList',
              { id: calculationId },
              (draft) => {
                changedCreateComplexRow(draft.rows, updatedRowsList.current);
                changedComplexSumRow(draft.rows, updatedRowsList.changed);
              }
            )
          );
        } catch (err) {
          console.log('error createComplexRow', err);
        }
      }
    }),
    deleteComplexRow: build.mutation<
      ComplexBaseResponse,
      DeleteRowByIdCalculationRequest
    >({
      query: ({ calculationId, rowId }) => {
        return {
          url: `calculation/${calculationId}/complex/row/${rowId}/delete`,
          method: 'DELETE'
        };
      },
      async onQueryStarted({ calculationId }, { dispatch, queryFulfilled }) {
        try {
          const { data: deleteRow } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getComplexRowsList',
              { id: calculationId },
              (draft) => {
                function changedDeleteRowComplex(arr: BasePricesItem[]) {
                  arr.forEach((elem) => {
                    if (elem.id === deleteRow.current.parent) {
                      const deleteLevelIndex = elem.children.findIndex(
                        (elem) => {
                          return elem.id === deleteRow.current.id;
                        }
                      );
                      elem.children.splice(deleteLevelIndex, 1);
                    }
                    if (elem.id === deleteRow.current.id) {
                      const deleteLevelIndex = arr.findIndex((elem) => {
                        return elem.id === deleteRow.current.id;
                      });
                      arr.splice(deleteLevelIndex, 1);
                    }
                    return changedDeleteRowComplex(elem.children);
                  });
                }

                changedDeleteRowComplex(draft.rows);
              }
            )
          );
          dispatch(
            calculationsApi.util.updateQueryData(
              'getComplexRowsList',
              { id: calculationId },
              (draft) => {
                const changedLevel1 = draft.rows?.find(
                  (row) => row.id === deleteRow.changed.slice(-1)[0].id
                );

                function changedSum(changedLevel1: BasePricesItem) {
                  if (!changedLevel1.children.length) {
                    return;
                  }
                  draft.rows.forEach((level1) => {
                    if (level1.id === changedLevel1.id) {
                      Object.assign(level1, {
                        ...deleteRow.changed.slice(-1)[0]
                      });
                    }
                  });
                  changedLevel1.children.forEach((level) => {
                    deleteRow.changed.forEach((changedElem) => {
                      if (level.id === changedElem.id) {
                        Object.assign(level, { ...changedElem });
                      }
                    });
                    return changedSum(level);
                  });
                } // changedSum
                if (changedLevel1) changedSum(changedLevel1);
              }
            ) // calculationsApi
          ); // close dispatch
        } catch (err) {
          console.log('error deleteComplexRow', err);
        }
      }
      //  invalidatesTags: ['ComplexList'],
    }),
    editComplexRow: build.mutation<
      ComplexBaseResponse,
      GetEditComplexRowByIdRequest
    >({
      query: ({ calculationId, rowId, ...body }) => {
        return {
          url: `calculation/${calculationId}/complex/row/${rowId}/edit`,
          method: 'POST',
          body
        };
      },
      async onQueryStarted({ calculationId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedRowsList } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getComplexRowsList',
              { id: calculationId },
              (draft) => {
                changedComplexEditRow(draft.rows, updatedRowsList.current);
                changedComplexSumRow(draft.rows, updatedRowsList.changed);
              }
            ) // calculationsApi
          ); // dispatch
          // изм цены
        } catch (err) {
          console.log('error editComplexRow', err);
        }
      }
      //  invalidatesTags: ['ComplexList'],
    }),
    copyComplexLevel: build.mutation<ComplexBaseResponse, RowCopyRequest>({
      query: ({ id: calculationId, rowId }) => {
        return {
          url: `/calculation/${calculationId}/complex/row/${rowId}/copy`,
          method: 'POST'
        };
      },
      async onQueryStarted(
        { id: calculationId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: updatedRowsList } = await queryFulfilled;
          dispatch(
            calculationsApi.util.updateQueryData(
              'getComplexRowsList',
              { id: calculationId },
              (draft) => {
                changedCopyComplexRow(draft.rows, updatedRowsList.current);
                changedComplexSumRow(draft.rows, updatedRowsList.changed);
              }
            )
          );
        } catch (err) {
          console.log('error copyComplexLevel', err);
        }
      }
      // invalidatesTags: ['ComplexList'],
    }),
    editCoefficients: build.mutation<unknown, CoefficientAddRequest>({
      query: ({ calculationId, ...body }) => {
        return {
          url: `/calculation/${calculationId}/complex/coefficient/edit`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['ComplexList']
    }),
    uploadExcel: build.mutation<unknown, UploadExcelRequest>({
      query: ({ calcID, projectID, body }) => {
        return {
          url: `/project/${projectID}/calc/${calcID}/upload`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['ComplexList']
    }),
    // uploadXML: build.mutation<unknown, UploadExcelRequest>({
    //   query: ({ calcID, body }) => {
    //     return {
    //       url: `/calculation/${calcID}/complicated/upload`,
    //       method: 'POST',
    //       body,
    //     };
    //   },
    //   invalidatesTags: ['ComplexList'],
    // }),
    getComplicatedRowsList: build.query<
      GetComplicatedListResponse,
      GetRowsListRequest
    >({
      query: ({ isPreview, projectID, id }) => {
        let url: string;
        if (isPreview) {
          url = `/project/${projectID}/lsr/files/${id}/preview`;
        } else {
          url = `/calculation/${id}/complicated/get`;
        }

        return {
          url,
          method: 'GET'
        };
      },
      transformResponse: (resp: GetComplicatedListResponse) => {
        console.log('server response >>>', resp);

        function flattenChildren(data: IComplicated) {
          const result: IComplicated[] = [{ ...data, parent_id: null }]; // Добавляем корневой объект в итоговый массив
          // data.parts = splitAndSort(data.parts)
          // console.log(acts,other);
          function flatten(children: IComplicated[], parent: number) {
            for (const child of children) {
              result.push({ ...child, parent_id: parent });
              if (child.children && child.children.length > 0) {
                flatten(child.children, child.id);
              }
            }
          }

          if (data.children && data.children.length > 0) {
            flatten(data.children, data.id);
          }

          return result;
        }

        // if (resp.total.length) {
        //   resp.total = [{ ...base, parts: filteredTotal }];
        // } else {
        //   resp.total = [{ ...base, ...placeholder }];
        // }
        const finalTree: any[] = [];
        for (const elem of resp.data) {
          finalTree.push(flattenChildren(elem));
        }
        resp.data = finalTree.flat();
        resp.total = resp.total || resp.data[0];
        resp.total.type = 'sum';
        resp.total.id = -1;
        console.log('<<<resp calculation>>>', resp);
        return resp;
      },
      providesTags: ['СomplicatedList']
    }),
    getVorPositions: build.query<
      GetVorPositionsResponse,
      GetVorPositionsRequest
    >({
      query: ({ calcId, rowIDs, isHandbook }) => ({
        url: `/calculation/${calcId}/${isHandbook ? 'handbook' : 'complicated'}/vor/get`,
        params: { rowIDs },
        method: 'GET'
      }),
      // transformResponse(baseQueryReturnValue: GetVorPositionsResponse, meta, arg) {
      //   const data = baseQueryReturnValue.data.map((item, index) => {
      //     return item;
      //   });
      //
      //   return { ...baseQueryReturnValue, data };
      // },
      providesTags: ['ComplexList', 'HandbookVor']
    }),
    getFilters: build.mutation<
      GetFilteredVorPositionsResponse,
      GetFilteredVorPositionsRequest
    >({
      query: ({ calcID, body }) => {
        return {
          url: `/calculation/${calcID}/complicated/vor/filters/get-narrowed-filter-data`,
          method: 'POST',
          body
        };
      }
    }),
    applyFilters: build.mutation<
      GetVorPositionsResponse,
      GetFilteredVorPositionsRequest
    >({
      query: ({ calcID, body }) => {
        return {
          url: `/calculation/${calcID}/complicated/vor/filters/apply`,
          method: 'POST',
          body
        };
      }
    }),
    createKeyWork: build.mutation<GetKeyWorkResponse, GetKeyWorkRequest>({
      query: ({ id: calculationId, ...body }) => {
        return {
          url: `/calculation/${calculationId}/complicated/vor/create`,
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['ComplexList']
    }),

    getVor: build.query<any, any>({
      query: ({
        calcId,
        vorId,
        isHandbook
      }: {
        calcId: number;
        vorId: number;
        isHandbook?: boolean;
      }) => {
        return {
          url: `/calculation/${calcId}/${isHandbook ? 'handbook' : 'complicated'}/vor/${vorId}/get`,
          method: 'GET'
        };
      },
      keepUnusedDataFor: 0
    }),

    editVor: build.mutation<any, any>({
      query: ({
        calcId,
        vorId,
        ...body
      }: {
        calcId: number;
        vorId: number;
      }) => {
        return {
          url: `/calculation/${calcId}/complicated/vor/${vorId}/edit`,
          method: 'POST',
          ...body
        };
      },
      invalidatesTags: ['ComplexList']
    }),

    deleteVor: build.mutation<any, any>({
      query: ({
        calcId,
        vorId,
        isHandbook
      }: {
        calcId: number;
        vorId: number;
        isHandbook?: boolean;
      }) => {
        return {
          url: `/calculation/${calcId}/${isHandbook ? 'handbook' : 'complicated'}/vor/${vorId}/delete`,
          method: 'DELETE'
        };
      },

      invalidatesTags: ['ComplexList', 'HandbookVor']
    }),
    downloadDataTreeExcel: build.query<
      string,
      DownloadExcelRequest & { isHandbook?: boolean }
    >({
      query: ({ calcID, isHandbook }) => ({
        url: `/calculation/${calcID}/${isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'}/download`,
        method: 'GET',
        responseHandler: 'text'
      })
    }),
    changeLevelName: build.mutation<
      void,
      ChangeLevelNameRequest & { isHandbook?: boolean }
    >({
      query: ({ params, body, isHandbook }) => ({
        url: `/calculation/${params.calcID}/${isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'}/${
          params.groupID
        }/edit`,
        method: 'POST',
        body
      })
    }),
    createLevel: build.mutation<
      void,
      CreateLevelRequest & { isHandbook?: boolean }
    >({
      query: ({ params, body, isHandbook }) => ({
        url: `/calculation/${params.calcID}/${
          isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'
        }/create`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result ? ['VORList'] : [])
    }),
    changeLevel: build.mutation<
      void,
      ChangeLevelRequest & { isHandbook?: boolean }
    >({
      query: ({ params, body, isHandbook }) => ({
        url: `/calculation/${params.calcID}/${
          isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'
        }/change-level`,
        method: 'POST',
        body
      })
    }),
    renameLevel: build.mutation<
      void,
      RenameLevelRequest & { isHandbook?: boolean }
    >({
      query: ({ params, body, isHandbook }) => ({
        url: `/calculation/${params.calcID}/${
          isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'
        }/rename`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result ? ['VORList'] : [])
    }),
    getGroupPosition: build.query<
      GetGroupPositionResponse,
      CalcID & { isHandbook?: boolean }
    >({
      query: ({ calcID, isHandbook }) => ({
        url: `/calculation/${calcID}/${isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'}/get`,
        method: 'GET'
      }),
      providesTags: ['VORList'],
      keepUnusedDataFor: 0
    }),
    automaticTreeProtection: build.query<
      AutomaticTreeProtectionResponse,
      AutomaticTreeProtectionRequest & { isHandbook?: boolean }
    >({
      query: ({ calcID, isHandbook }) => ({
        url: `/calculation/${calcID}/${isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'}/auto-forming`,
        method: 'GET'
      })
    }),
    bindGroup: build.mutation<
      void,
      BindGroupRequest & { isHandbook?: boolean }
    >({
      query: ({ params, body, isHandbook }) => ({
        url: `/calculation/${params.calcID}/${isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'}/${
          params.groupID
        }/bind`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result ? ['VORList'] : [])
    }),
    deleteGroup: build.mutation<
      void,
      DeleteGroupRequest & { isHandbook?: boolean }
    >({
      query: ({ calcID, groupID, isHandbook, ...params }) => ({
        url: `/calculation/${calcID}/${
          isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'
        }/${groupID}/delete`,
        method: 'DELETE',
        params
      }),
      invalidatesTags: (result) => (result ? ['VORList'] : [])
    }),
    transferElementToTheGroup: build.mutation<
      void,
      TransferElementToTheGroupRequest & { isHandbook?: boolean }
    >({
      query: ({ params, body, isHandbook }) => ({
        url: `/calculation/${params.calcID}/${
          isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'
        }/transfer`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result) => (result !== undefined ? ['VORList'] : [])
    }),
    getAvailableGroups: build.mutation<
      GetAvailableGroupsResponse,
      GetAvailableGroupsRequest & { isHandbook?: boolean }
    >({
      query: ({ params, body, isHandbook }) => ({
        url: `/calculation/${params.calcID}/${
          isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'
        }/available-for-insert`,
        method: 'POST',
        body
      })
    }),
    clearEmptyLevels: build.mutation<
      void,
      ClearEmptyLevelsRequest & { isHandbook?: boolean }
    >({
      query: ({ calcID, isHandbook }) => ({
        url: `/calculation/${calcID}/${isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'}/clear`,
        method: 'DELETE'
      }),
      invalidatesTags: (result) => (result !== undefined ? ['VORList'] : [])
    }),

    getResourceList: build.query<
      TransformedResourceResponse,
      GetResourceListRequest
    >({
      query: ({ params, body }) => ({
        url: `/calculation/${params.calcID}/complicated/resources/get`,
        method: 'POST',
        body
      }),
      transformResponse: (
        resp: GetResourceListResponse,
        _,
        t: unknown
      ): TransformedResourceResponse => {
        const returnedData: TransformedResourceResponse = {
          data: [],
          totalByRange: []
        };

        const rows = {
          tzr: 'Затраты труда рабочих',
          tzm: 'Затраты труда машинистов',
          mim: 'Машины и Механизмы',
          materials: 'Материалы',
          equipments: 'Оборудование'
        };

        const base = {
          baseTotalByUnit: null,
          code: null,
          currTotalByUnit: null,
          documentCode: null,
          lsrCode: null,
          parent_id: null,
          weight: null,
          quantity: null,
          rdStamp: null,
          repeatCount: null,
          index: null,
          type: null,
          unit: null,
          subType: null
        };

        returnedData.totalByRange = [
          { ...base, id: -1, subType: 'sum', ...resp.totalByRange }
        ];
        const d: ComplicatedResource[] = [];
        const namesGroup = Object.keys(rows) as Array<keyof typeof rows>;
        console.time('t');
        let index = 1;
        resp.data.forEach((file) => {
          const { rows: r, splitRows, ...rest } = file;
          if (
            t &&
            typeof t === 'object' &&
            'body' in t &&
            Array.isArray(t.body) &&
            !Object.keys(t.body).length
          ) {
            d.push({ ...base, ...rest, parent_id: null, subType: 'file' });
          }
          const groupR = groupBy(r, 'type');
          const groupSplit = groupBy(splitRows, 'type');
          let key: keyof typeof rows;
          for (key of namesGroup) {
            if (key in groupR && groupR[key].length) {
              const newIndex = d.length;

              const chapter: ComplicatedResource = {
                ...base,
                title: rows[key],
                baseTotal: null,
                currTotal: null,
                id: index,
                parent_id: file.id ?? null,
                subType: 'level'
              };

              let currTotal = 0;

              d.push({} as ComplicatedResource);

              const sum = groupR[key].reduce((acc, val) => {
                acc += val.baseTotal ?? 0;
                return acc;
              }, 0);

              groupR[key]
                .sort((a, b) => ((a.code ?? '') > (b.code ?? '') ? 1 : -1))
                .forEach((tzr, idx) => {
                  d.push({
                    ...tzr,
                    parent_id: file.id ?? null,
                    subType: 'row',
                    weight:
                      tzr.baseTotal !== 0 || sum !== 0
                        ? Math.abs((tzr.baseTotal ?? 0) / sum) * 100
                        : 0,
                    index: idx + 1
                  });
                  currTotal += tzr.currTotal || 0;
                });
              d.splice(newIndex, 1, { ...chapter, baseTotal: sum, currTotal });
            }
            index++;
          }
          if (splitRows.length) {
            d.push({
              ...base,
              title: 'Ресурсы Заказчика\n',
              baseTotal: null,
              currTotal: null,
              id: index,
              parent_id: file.id ?? null,
              subType: 'otherFile'
            });
          }
          for (key of namesGroup) {
            if (key in groupSplit && groupSplit[key].length) {
              index++;
              const newIndex = d.length;
              const chapter: ComplicatedResource = {
                ...base,
                title: rows[key],
                baseTotal: null,
                currTotal: null,
                id: index,
                parent_id: file.id ?? null,
                subType: 'level'
              };
              d.push({} as ComplicatedResource);

              let currTotal = 0;

              const sum = groupSplit[key].reduce((acc, val) => {
                acc += val.baseTotal ?? 0;
                return acc;
              }, 0);

              groupSplit[key]
                .sort((a, b) => ((a.code ?? '') > (b.code ?? '') ? 1 : -1))
                .forEach((tzr, idx) => {
                  d.push({
                    ...tzr,
                    parent_id: file.id ?? null,
                    subType: 'row',
                    weight:
                      tzr.baseTotal !== 0 || sum !== 0
                        ? Math.abs((tzr.baseTotal ?? 0) / sum) * 100
                        : 0,
                    index: idx + 1
                  });
                  currTotal += tzr.currTotal || 0;
                });

              d.splice(newIndex, 1, { ...chapter, baseTotal: sum, currTotal });
            }
            index++;
          }
          index++;

          console.timeEnd('t');
          return file;
        });

        returnedData.data = d;

        console.log('<<<response resource>>>', returnedData);

        return returnedData;
      },
      providesTags: ['Resources']
    }),

    getResourceTotalSum: build.query<
      Total,
      Pick<GetResourceListRequest, 'params'>
    >({
      query: ({ params }) => ({
        url: `/calculation/${params.calcID}/complicated/resources/get-total-sum`,
        method: 'GET'
      }),
      providesTags: ['Resources']
    }),

    getPositionList: build.query<
      ComplicatedPositionTreeFile[],
      GetPositionListRequest
    >({
      query: ({ calcID, ...params }) => ({
        url: `/calculation/${calcID}/complicated/resources/get-positions`,
        method: 'GET',
        params
      })
    }),

    ungroupAllLevels: build.query<unknown, CalcID & { isHandbook?: boolean }>({
      query: ({ calcID, isHandbook }) => ({
        url: `/calculation/${calcID}/${isHandbook ? 'handbook/vor/grouping' : 'complicated/vor-grouping'}/erase-groups`,
        method: 'GET'
      })
    }),

    getExecutionCalculation: build.query<GetExecutionCalculationData, CalcID>({
      query: ({ calcID }) => ({
        url: `/calculation/${calcID}/complicated/execution/get`,
        method: 'GET'
      }),
      transformResponse: (resp: GetExecutionCalculationData) => {
        const base: ExecutionCalculationData = {
          id: -1,
          type: 'sum',
          number: 1,
          lsr: '',
          chapter: null,
          header: null,
          children: [],
          code: null,
          subtype: null,
          title: '',
          unit: '',
          parts: [
            {
              actID: null,
              type: 'total',
              quantity: null,
              startDate: null,
              endDate: null,
              base: {
                materialsPrice: null,
                equipmentPrice: null,
                constructionEffort: null,
                workersSalary: null,
                mechanicsEffort: null,
                mimExploitation: null,
                mechanicsSalary: null,
                hp: null,
                overheads: null,
                sp: null,
                estimatedProfit: null,
                totalWorkCost: null,
                workCostInRubles: null,
                dynamicRows: []
              },
              curr: {
                materialsPrice: null,
                equipmentPrice: null,
                constructionEffort: null,
                workersSalary: null,
                mechanicsEffort: null,
                mimExploitation: null,
                mechanicsSalary: null,
                hp: null,
                overheads: null,
                sp: null,
                estimatedProfit: null,
                totalWorkCost: null,
                workCostInRubles: null,
                dynamicRows: []
              }
            },
            {
              actID: null,
              type: 'executed',
              quantity: null,
              startDate: null,
              endDate: null,
              base: {
                materialsPrice: null,
                equipmentPrice: null,
                constructionEffort: null,
                workersSalary: null,
                mechanicsEffort: null,
                mimExploitation: null,
                mechanicsSalary: null,
                hp: null,
                overheads: null,
                sp: null,
                estimatedProfit: null,
                totalWorkCost: null,
                workCostInRubles: null,
                dynamicRows: []
              },
              curr: {
                materialsPrice: null,
                equipmentPrice: null,
                constructionEffort: null,
                workersSalary: null,
                mechanicsEffort: null,
                mimExploitation: null,
                mechanicsSalary: null,
                hp: null,
                overheads: null,
                sp: null,
                estimatedProfit: null,
                totalWorkCost: null,
                workCostInRubles: null,
                dynamicRows: []
              }
            },
            {
              actID: null,
              type: 'rest',
              quantity: null,
              startDate: null,
              endDate: null,
              base: {
                materialsPrice: null,
                equipmentPrice: null,
                constructionEffort: null,
                workersSalary: null,
                mechanicsEffort: null,
                mimExploitation: null,
                mechanicsSalary: null,
                hp: null,
                overheads: null,
                sp: null,
                estimatedProfit: null,
                totalWorkCost: null,
                workCostInRubles: null,
                dynamicRows: []
              },
              curr: {
                materialsPrice: null,
                equipmentPrice: null,
                constructionEffort: null,
                workersSalary: null,
                mechanicsEffort: null,
                mimExploitation: null,
                mechanicsSalary: null,
                hp: null,
                overheads: null,
                sp: null,
                estimatedProfit: null,
                totalWorkCost: null,
                workCostInRubles: null,
                dynamicRows: []
              }
            }
          ]
        };

        function flattenChildren(data: ExecutionCalculationData) {
          const result: ExecutionCalculationData[] = [
            { ...data, parent_id: null }
          ]; // Добавляем корневой объект в итоговый массив
          // data.parts = splitAndSort(data.parts)
          // console.log(acts,other);
          function flatten(
            children: ExecutionCalculationData[],
            parent: number
          ) {
            for (const child of children) {
              result.push({ ...child, parent_id: parent });
              if (child.children && child.children.length > 0) {
                flatten(child.children, child.id);
              }
            }
          }

          if (data.children && data.children.length > 0) {
            flatten(data.children, data.id);
          }

          return result;
        }

        const filteredTotal = (resp.total as never[]).map(
          (group: PartExecution) => {
            const { hp: hpCurr, sp: spCurr, ...restCurr } = group.curr;
            const { hp: hpBase, sp: spBase, ...restBase } = group.base;

            const curr = { ...restCurr, hp: null, sp: null, quantity: null };
            const base = { ...restBase, hp: null, sp: null, quantity: null };

            group.base = base;

            group.curr = curr;

            return group;
          }
        );

        if (resp.total.length) {
          resp.total = [{ ...base, parts: filteredTotal }];
        } else {
          resp.total = [{ ...base }];
        }
        const finalTree: ExecutionCalculationData[] = [];
        for (const elem of resp.tree) {
          finalTree.push(...flattenChildren(elem));
        }
        resp.tree = finalTree.flat();
        console.log('<<<resp execution>>>', resp);
        return resp;
      },
      providesTags: ['ExecutionList']
    }),
    getActList: build.query<ActResponse, CalcID>({
      query: ({ calcID, ...params }) => ({
        url: `/calculation/${calcID}/execution/acts/list`,
        // url: `/calculation/${calcID}/complicated/execution/acts/list`,
        method: 'GET',
        params
      }),

      transformResponse(response: ActResponse) {
        response.estimateActs.sort((a, b) =>
          (a?.estimateName || '') < (b?.estimateName || '') ? 1 : -1
        );
        return response;
      },
      providesTags: ['ActList']
    }),
    getHandbookExecutionList: build.query<
      HandbookExecutionListResponse,
      CalcID
    >({
      query: ({ calcID, ...params }) => ({
        url: `/calculation/${calcID}/handbook/execution/get`,
        method: 'GET',
        params
      }),
      providesTags: ['HandbookExecutionList']
    }),
    createAct: build.mutation<ActList, CreateActRequest>({
      query: ({ calcID, body }) => ({
        url: `/calculation/${calcID}/complicated/execution/acts/create`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['ActList', 'ExecutionList', 'HandbookExecutionList']
    }),
    editQuantity: build.mutation<unknown, EditQuantityRequest>({
      query: ({ calcID, actID, body }) => ({
        url: `/calculation/${calcID}/execution/acts/${actID}/set-quantity`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['ExecutionList', 'HandbookExecutionList']
    }),
    syncAct: build.mutation<SyncActResponse, SyncActRequest>({
      query: ({ calcID, actID }) => ({
        url: `/calculation/${calcID}/execution/acts/${actID}/synchronizations/rm/period/send`,
        method: 'POST'
      }),
      invalidatesTags: ['ActList']
    }),
    syncGetFact: build.mutation<SyncActResponse, SyncActRequest>({
      query: ({ calcID, actID }) => ({
        url: `/calculation/${calcID}/execution/acts/${actID}/synchronizations/rm/period/collect`,
        method: 'POST'
      }),
      invalidatesTags: ['ExecutionList', 'ActList', 'HandbookExecutionList']
    }),
    syncCloseAct: build.mutation<unknown, SyncActRequest>({
      query: ({ calcID, actID }) => ({
        url: `/calculation/${calcID}/execution/acts/${actID}/synchronizations/rm/period/close`,
        method: 'POST'
      }),
      invalidatesTags: ['ExecutionList', 'ActList', 'HandbookExecutionList']
    })
  }),

  overrideExisting: false
});

export const {
  useEditCalculationByIdMutation,
  useGetCalculationListQuery,
  useCreateCalculationMutation,
  useGetCalculationByIdQuery,
  useGetLsrPreviewQuery,
  useEditCalculationStatusMutation,
  useDeleteCalculationMutation,
  useCopyCalculationMutation,
  useDownloadCalculationMutation,
  useEditCalculationMutation,
  useGetBasicRowsListQuery,
  useCreateBasicLevelMutation,
  useCopyBasicLevelMutation,
  useCreateRowMutation,
  useDeleteRowMutation,
  useEditTitleLevelMutation,
  useEditRowMutation,
  useGetCalculationByIdPreviewQuery,
  useGetComplexRowsListQuery,
  useCreateComplexLevelMutation,
  useCreateComplexRowMutation,
  useDeleteComplexRowMutation,
  useEditComplexRowMutation,
  useCopyComplexLevelMutation,
  useEditCoefficientsMutation,
  useUploadExcelMutation,
  useGetComplicatedRowsListQuery,
  useGetVorPositionsQuery,
  useCreateKeyWorkMutation,
  useGetVorQuery,
  useLazyGetVorQuery,
  useEditVorMutation,
  useDeleteVorMutation,
  useLazyDownloadDataTreeExcelQuery,
  useChangeLevelNameMutation,
  useDeleteGroupMutation,
  useCreateLevelMutation,
  useGetGroupPositionQuery,
  useLazyGetGroupPositionQuery,
  useBindGroupMutation,
  useLazyAutomaticTreeProtectionQuery,
  useChangeLevelMutation,
  useTransferElementToTheGroupMutation,
  useGetAvailableGroupsMutation,
  useRenameLevelMutation,
  useClearEmptyLevelsMutation,
  useGetResourceListQuery,
  useLazyGetResourceListQuery,
  useGetPositionListQuery,
  useGetResourceTotalSumQuery,
  useLazyUngroupAllLevelsQuery,

  useGetExecutionCalculationQuery,
  useLazyGetExecutionCalculationQuery,

  useGetHandbookExecutionListQuery,

  useEditQuantityMutation,

  useCalculationUploadFilesMutation,

  useGetActListQuery,
  useLazyGetActListQuery,
  useCreateActMutation,

  useSyncActMutation,
  useSyncCloseActMutation,
  useSyncGetFactMutation,

  useApplyFiltersMutation,
  useGetFiltersMutation
} = calculationsApi;
